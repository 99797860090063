import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivate } from '@angular/router';
import { Observable } from 'rxjs';
import firebase from 'firebase/app';
import 'firebase/auth';

import { environment } from '@env/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {
    if (environment.emulator) {
      firebase.auth().useEmulator('http://localhost:9099/');
      console.warn('EMULATOR auth');
    }
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return new Promise((resolve, reject) => {
      this.auth.user$.subscribe((user) => {
        if (user) {
          if (!user.emailVerified) {
            console.error('AuthGuard: email NOT verified', user);
            this.router.navigate(['/register', { loginButNotVerified: 'required' }]);
            resolve(false);
          } else if (!user.lastName) {
            console.error('AuthGuard: userData NOT found', user);
            this.router.navigate(['/register', { loginUserDataNotFound: 'required' }]);
            resolve(false);
          } else if (!user.roles?.user) {
            console.error('AuthGuard: User ist deaktiviert');
            this.router.navigate(['/login-error', { userNotActive: 'true' }]);
            resolve(false);
          } else {
            resolve(true);
          }
        } else {
          console.log('AuthGuard: User is not logged in');
          this.router.navigate(['/login']);
          resolve(false);
        }
      });
    });
  }
}
