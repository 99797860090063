import { Router } from '@angular/router';
import { Injectable } from '@angular/core';

import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';

import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

import * as moment from 'moment';

import { Observable, of } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';

import { environment } from '@env/environment';

import { User } from '@interfaces/user';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user$: Observable<User>;

  constructor(private afAuth: AngularFireAuth, private afs: AngularFirestore, private router: Router) {
    if (environment.emulator) {
      firebase.auth().useEmulator('http://localhost:9099/');
    }

    this.user$ = this.afAuth.authState.pipe(
      switchMap((user) => {
        if (user) {
          return this.afs
            .doc<User>(`userProfile/${user.uid}`)
            .valueChanges()
            .pipe(
              map((u) => {
                if (u) {
                  u.uid = user.uid;
                  u.lastSignInTime = user.metadata.lastSignInTime;
                  u.creationTime = user.metadata.creationTime;
                  if (!u.fcmTokens) u.fcmTokens = [];
                  if (!u.bookedBodiesIds) u.bookedBodiesIds = [];
                } else console.warn('afAuth AUTHSTATE afs.MAP NO u', user.uid, user);
                return u;
              })
            );
        } else {
          return of(null);
        }
      })
    );
  }

  loginUser(email: string, password: string): Promise<any> {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  }

  registerUser(email: string, password: string): Promise<any> {
    return firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((newUserCredential) => {
        console.warn('new userProfile', newUserCredential, email);
        // eintrag in DB wird per cloud functions erledigt...
      })
      .catch((error) => {
        console.error(error);
        if (error.code == 'auth/email-already-in-use')
          throw new Error('Eine Registrierung mit dieser E-Mail-Adresse ist derzeit leider nicht möglich.');
        else throw new Error(error.message);
      });
  }

  resetPassword(email: string): Promise<void> {
    return firebase.auth().sendPasswordResetEmail(email);
  }

  logoutUser(): Promise<void> {
    return this.afAuth.signOut();
    //return firebase.auth().signOut();
  }

  reloadUser() {
    const user = firebase.auth().currentUser;
    user.reload();
    return user;
  }

  // Store user data in DB
  storeUserData(user: {
    uid: string;
    firstName: string;
    lastName: string;
    organization: string;
    title: string;
    street: string;
    zip: string;
    city: string;
  }) {
    if (user.uid) {
      const userRef: firebase.firestore.DocumentReference<any> = firebase.firestore().doc(`userProfile/${user.uid}`);
      return userRef.set(user, { merge: true });
    } else return Promise.reject(new Error('no uid'));
  }

  async checkUserVerificationCode(email: string, code: string) {
    let checkCode = 0;
    let userId = null;
    await firebase
      .firestore()
      .collection('userProfile')
      .where('email', '==', email)
      .get()
      .then(async (querySnapshot) => {
        if (querySnapshot.size == 1) {
          querySnapshot.forEach(async (userSnap) => {
            if (userSnap.data().random == code && !userSnap.data().randomVerified) {
              checkCode = 1;
              userId = userSnap.id;
              // set DB to verified!
              userSnap.ref
                .set({ emailVerified: true, randomVerified: code, verified_at: new Date() }, { merge: true })
                .catch((error) => console.error('userSNAP.ref.set ERROR', error));
            } else checkCode = 2;
          });
        } else {
          console.error('checkUserVerificationCode - no user found');
          throw new Error('no user found');
        }
      })
      .catch((err) => console.error('checkUserVerificationCode - catch', err));

    return { checkCode, userId };
  }

  async checkUserInviteCode(email: string, code: string) {
    let checkCode = 0;
    let userId = null;
    let errorText = null;

    try {
      // login user (with default pwd)
      await firebase
        .auth()
        .signInWithEmailAndPassword(email, 'f649f4bd58bcba407')
        .then((userCredential) => {
          console.log('user logged in', userCredential);
        })
        .catch(async (error) => {
          console.error('no login', error);
          checkCode = 2;

          /*const chck = await this.checkAlreadyUsedInvite(email, code);
          if (chck == 1) {
            errorText = 'Die Einladung wurde bereits aktiviert. Bitte melden Sie sich mit Ihrem Passwort an.';
            checkCode = 3;
          } else */

          errorText = 'Die Einladung wurde nicht gefunden oder ist nicht mehr aktiv.';
        });

      if (checkCode > 0) {
        return Promise.resolve({
          checkCode,
          errorText,
        });
      }

      // check invite code
      await firebase
        .firestore()
        .collection('userProfile')
        .where('email', '==', email)
        .get()
        .then(async (querySnapshot) => {
          if (querySnapshot.size == 1) {
            querySnapshot.forEach(async (userSnap) => {
              if (userSnap.data().invite == code) {
                // invite found!
                userId = userSnap.id;
                checkCode = 1;

                // check: invite zu alt?
                const max10Days = moment(userSnap.data().created_at.toDate()).add(10, 'd').isAfter();
                console.log('max10Days', max10Days);

                if (!max10Days) {
                  checkCode = 2;
                  errorText = 'Die User-Einladung ist bereits abgelaufen.';
                }
              } else {
                console.warn('no invite', userSnap.data());
                checkCode = 2;
                errorText = 'Die User-Einladung wurde nicht gefunden, Bitte den Link aus der E-Mail prüfen!';
              }
            });
          } else {
            console.error('checkUserInviteCode - no user found');
            checkCode = 2;
            errorText = 'Die User-Einladung wurde nicht gefunden oder ist nicht mehr aktiv.';
          }
        })
        .catch((err) => console.error('checkUserInviteCode - catch', err));

      if (userId)
        return Promise.resolve({
          userId,
          checkCode,
          errorText,
        });
      else {
        // logout!
        firebase.auth().signOut();

        if (checkCode > 0) {
          return Promise.resolve({
            checkCode,
            errorText,
          });
        } else throw new Error('unbekannter Fehler');
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async checkAlreadyUsedInvite(email: string, code: string) {
    let chck = 0;
    // check invite code
    await firebase
      .firestore()
      .collection('userProfile')
      .where('email', '==', email)
      .where('invite', '==', code)
      .limit(1)
      .get()
      .then(async (querySnapshot) => {
        if (querySnapshot.size == 1) {
          querySnapshot.forEach(async (userSnap) => {
            if (userSnap.data().invite && userSnap.data().roles?.user) chck = 1;
            else {
              console.error('checkAlreadyUsedInvite - no invite found !?');
              chck = 2;
            }
          });
        }
      })
      .catch((error) => {
        console.error('checkAlreadyUsedInvite - error !?', error);
        chck = 2;
      });

    return chck;
  }

  async changeInvitePwd(email, pwd) {
    const user = this.reloadUser();
    const credential = firebase.auth.EmailAuthProvider.credential(email, 'f649f4bd58bcba407');

    user
      .reauthenticateWithCredential(credential)
      .then(async () => {
        // User re-authenticated.
        console.log('reauthenticateWithCredential THEN');

        await user.updatePassword(pwd).catch((error) => {
          throw new Error(`updatePassword error: ${error}`);
        });

        await this.finishInvite(user);
      })
      .catch((error) => {
        // An error ocurred
        // ...
        console.error('reauthenticateWithCredential CATCH', error);
        return Promise.reject(error);
      });

    return Promise.resolve(user);
  }

  async finishInvite(user: firebase.User) {
    if (user.uid && user.emailVerified) {
      const userRef: firebase.firestore.DocumentReference<any> = firebase.firestore().doc(`userProfile/${user.uid}`);
      return userRef.set({ inviteFinished: true }, { merge: true });
    } else return Promise.reject(new Error('no uid'));
  }

  ///// Role-based Authorization //////

  canRead(user: User): boolean {
    const allowed = ['admin', 'editor', 'premium'];
    return this.checkAuthorization(user, allowed);
  }
  canEdit(user: User): boolean {
    const allowed = ['admin', 'editor'];
    return this.checkAuthorization(user, allowed);
  }
  canDelete(user: User): boolean {
    const allowed = ['admin'];
    return this.checkAuthorization(user, allowed);
  }

  // determines if user has matching role
  private checkAuthorization(user: User, allowedRoles: string[]): boolean {
    if (!user) return false;
    for (const role of allowedRoles) {
      if (user.roles && user.roles[role]) {
        return true;
      }
    }
    return false;
  }
}
