<ion-content class="con-modal__content">

  <div class="con-modal__close-btn">
    <ion-icon name="close-circle" color="primary" (click)="dismissModal()"></ion-icon>
  </div>

  <h2>
    <fa-icon [icon]="['fal', 'map-marker-alt']" size="lg" class="fa-search-icon"></fa-icon>
    Wo wollen Sie suchen?</h2>


  <ion-grid class="select-bundesland-grid">
    <ion-row class="select-bundesland-grid-row ion-justify-content-center">
      <ion-col *ngFor="let bl of listBundeslaender" class="select-bundesland-grid-col" size="1">
        <button  mat-raised-button class="mat-elevation-z2" [color]="selectedBundeslaender.includes(bl) ? 'primary': 'accent'" (click)="selectBL(bl)" class="btn-select-bundesland" [ngClass]="bl">{{bl}}</button>
      </ion-col>
    </ion-row>
    
  </ion-grid>

  

  <ion-grid class="selected-cities__grid">
    <ion-row>
      <ion-col size="6">

        <ion-list>

          <ion-list-header class="selected-cities__list-header">
            <ion-label color="primary">Ihre gebuchten Städte</ion-label>

            <fa-icon [icon]="['fas', 'check-square']" size="2x" class="fa-select-all" (click)="selectAll()"></fa-icon>
          </ion-list-header>

          <ng-container *ngFor="let bdy of listBodies">
            <ion-item *ngIf="!bdy.isSelected && (selectedBundeslaender.length == 0 || selectedBundeslaender.includes(bdy.bundesland_kurz))" [value]="bdy.id" (click)="selectCity(bdy.id)" class="selected-cities__list-item">
              <ion-label>{{bdy.city}} </ion-label>
              
              <ion-note slot="end">{{bdy.bundesland_kurz}}</ion-note>
            </ion-item>
          </ng-container>
          
        </ion-list>  

      </ion-col>

      <ion-col size="6">

        

        <ion-list>

          <ion-list-header>
            <ion-label color="primary" class="selected-cities-list-header">Ausgewählte Städte ({{countSelectedCities}})</ion-label>
            
            <fa-icon [icon]="['fal', 'times-square']" size="2x" class="fa-select-none" (click)="selectNone()"></fa-icon>
          </ion-list-header>

          <ng-container *ngFor="let bdy of listBodies">
            <ion-item *ngIf="bdy.isSelected" [value]="bdy.id" (click)="unselectCity(bdy.id)" class="selected-cities__list-item">
              <ion-label [color]="selectedBundeslaender.includes(bdy.bundesland_kurz) ? 'primary':''">{{bdy.city}} </ion-label>
              
              <ion-note slot="end">{{bdy.bundesland_kurz}}</ion-note>
            </ion-item>
          </ng-container>
          <ion-item  lines="none" class="ion-text-left" *ngIf="countSelectedCities==0">
            <p class="selected-cities__list-hint"><em>Bitte die Städte auswählen, in denen gesucht werden soll.</em></p>
          </ion-item>
          <ion-item lines="none" >
            <div class="ion-text-center ion-padding-vertical">
              <button mat-raised-button class="mat-elevation-z4" color="primary" (click)="saveAndClose()" [disabled]="countSelectedCities==0">Auswahl bestätigen</button>
            </div>
          </ion-item>
        </ion-list>  

      </ion-col>
    </ion-row>
  </ion-grid>


  


  
</ion-content>
  