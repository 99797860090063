import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';
import { Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class ScreensizeService {
  private isMobile = new BehaviorSubject<boolean>(false); // <768px
  private isTablet = new BehaviorSubject<boolean>(false); // switch menu on (>768 / ipad portrait)
  private isDesktop = new BehaviorSubject<boolean>(false); // search: auto-input (desktop) vs

  private plt = [];

  constructor(private platform: Platform) {
    this.platform.ready().then(() => {
      this.onResize(this.platform.width());
    });

    this.platform.resize.subscribe(async () => {
      this.onResize(this.platform.width());
    });
  }

  onResize(size) {
    if (size < 768) {
      this.isMobile.next(true);
      this.isTablet.next(false);
      this.isDesktop.next(false);
    } else if (size < 992) {
      this.isMobile.next(false);
      this.isTablet.next(true);
      this.isDesktop.next(false);
    } else {
      this.isMobile.next(false);
      this.isTablet.next(true);
      this.isDesktop.next(this.platform.is('desktop'));
    }
  }

  isMobileView(): Observable<boolean> {
    return this.isMobile.asObservable().pipe(distinctUntilChanged());
  }
  isTabletView(): Observable<boolean> {
    return this.isTablet.asObservable().pipe(distinctUntilChanged());
  }
  isDesktopView(): Observable<boolean> {
    return this.isDesktop.asObservable().pipe(distinctUntilChanged());
  }
}
