import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastController } from '@ionic/angular';

import firebase from 'firebase/app';
import 'firebase/messaging';

import { AngularFireMessaging } from '@angular/fire/messaging';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';

import { Subject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import * as moment from 'moment';

export interface Notification {
  id?: string;
  title: string;
  body: string;
  created_at?: Date;
  uid: string;
}

@Injectable({
  providedIn: 'root',
})
export class FcmService {
  private token: string;
  private fcm: firebase.messaging.Messaging;

  private messageSource = new Subject();
  currentMessage = this.messageSource.asObservable(); // message observable to show in Angular component

  private notifications: Observable<Notification[]>;
  private notificationCollection: AngularFirestoreCollection<Notification>;

  constructor(
    private afMessaging: AngularFireMessaging,
    private afs: AngularFirestore,
    private toastController: ToastController,
    private router: Router
  ) {
    if (this.isSupportedByBrowser()) {
      this.fcm = firebase.messaging();
    }
  }

  // get Push Permission, save Token
  getPermission(user): boolean {
    console.log('fcm - getPermission');
    if (this.isSupportedByBrowser()) {
      this.afMessaging.requestToken.subscribe(
        (token) => {
          console.log('fcmService getPermission -- Permission granted!'); // Save to the server!', token, this.token);
          this.token = token;
          this.saveToken(user, token);
          return true;
        },
        (error) => {
          console.error('fcmService getPermission ERROR', error);
        }
      );
    } else {
      console.warn('FCM is not supported!');
    }
    return false;
  }

  // Listen for token refresh
  monitorRefresh(user) {
    if (this.isSupportedByBrowser()) {
      this.fcm.onTokenRefresh(() => {
        this.fcm
          .getToken()
          .then((refreshedToken) => {
            console.log('Token refreshed.', refreshedToken);
            this.saveToken(user, refreshedToken);
          })
          .catch((err) => console.error(err, 'Unable to retrieve new token'));
      });
    }
  }

  // show message when app is open
  receiveMessages(user) {
    if (this.isSupportedByBrowser()) {
      this.fcm.onMessage((payload) => {
        console.warn('Message received. ', payload);
        this.showOnceToast(payload.notification.body);
        const msg = {
          title: payload.notification.title,
          body: payload.notification.body,
          icon: payload.notification.icon,
          link: payload.data['gcm.notification.link'],
        };

        this.messageSource.next(msg);
      });
    } else {
      // fallback: catch notifications from firestore!
      console.log('receiveMessages for not supported browser');
      const OneMinAgo = moment().subtract(1, 'minute').toDate();

      // get collection of notification
      this.notificationCollection = this.afs.collection<Notification>('notifications', (ref) =>
        ref.where('created_at', '>', OneMinAgo).orderBy('created_at', 'desc').limit(1)
      );

      // wait for changes
      this.notifications = this.notificationCollection.snapshotChanges().pipe(
        map((actions) => {
          return actions.map((a) => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        }),
        tap((n) => console.warn('tap', n))
      );

      this.notifications.subscribe((notification) => {
        console.log('receiveMsg subscribe', notification[0]);
        if (notification && notification[0] && user.uid == notification[0].uid) {
          this.showOnceToast(notification[0].body);
          const msg = {
            title: notification[0].title,
            body: notification[0].body,
          };
          this.messageSource.next(msg);
        } else console.log('msg for other user found', notification);
      });
    }
  }

  saveToken(user, token): void {
    const currentTokens = user.fcmTokens || {};

    // If token does not exist in firestore, update db
    if (!currentTokens[token]) {
      const userRef = this.afs.collection('userProfile').doc(user.uid);
      const tokens = { ...currentTokens, [token]: true };
      userRef.update({ fcmTokens: tokens });
    }
  }

  deleteToken(user) {
    this.fcm
      .getToken()
      .then((currentToken) => {
        this.fcm
          .deleteToken(currentToken)
          .then(() => {
            console.log('Token deleted.');
            this.token = null;
            this.deleteTokenFromDB(user, currentToken);
          })
          .catch((err) => {
            console.log('Unable to delete token. ', err);
          });
      })
      .catch((err) => {
        console.error('Error retrieving Instance ID token. ', err);
      });
  }

  deleteTokenFromDB(user, token): void {
    console.warn('deleteTokenFromDB', user, token);
    const userRef = this.afs.collection('userProfile').doc(user.uid);
    userRef.update({
      fcmTokens: firebase.firestore.FieldValue.arrayRemove(token),
    });
  }
  getLastToken() {
    return this.token;
  }

  isSupportedByBrowser() {
    return firebase.messaging.isSupported();
  }

  checkNotificatonStatus() {
    // Let's check if the browser supports notifications
    if (!('Notification' in window)) {
      console.log('This browser does not support desktop notification');
      return 'not_supported';
    } else if (Notification.permission) {
      return Notification.permission;
    }

    return false;
  }

  /* DB Stuff */

  triggerNewMessage(opts: { uid: string, title: string, body: string }) {
    try {
      if (!opts.body) throw new Error('no body!');

      const { serverTimestamp } = firebase.firestore.FieldValue;

      // Database Reference
      const msgRef = firebase.firestore().collection('notifications');

      msgRef
        .add({
          uid: opts.uid,
          title: opts.title,
          body: opts.body,
          created_at: serverTimestamp(),
        })
        .then(() => console.log('triggerNewMessage add THEN', opts))
        .catch((error) => console.error(`ERROR triggerNewMessage: ${error}`));
    } catch (error) {
      console.error('ERROR triggerNewMessage', error);
    }
  }

  getUserNotifications(uid: string): Observable<Notification[]> {
    return this.afs
      .collection<Notification>('notifications', (ref) =>
        ref.where('uid', '==', uid).orderBy('created_at', 'desc').limit(10)
      )
      .snapshotChanges()
      .pipe(
        map((actions) => {
          return actions.map((a) => {
            const data = a.payload.doc.data();
            const id = a.payload.doc.id;
            return { id, ...data };
          });
        }),
        tap((n) => console.warn('getUserNotifications', n))
      );
  }

  /* Toast */

  showFcmToast(message) {
    console.log('fcmService makeToast', message);
    this.toastController
      .create({
        // header: 'FCM',
        message,
        duration: 5000,
        position: 'bottom',
        color: 'primary',
        buttons: [
          {
            side: 'start',
            icon: 'list-outline',
            handler: () => {
              this.router.navigateByUrl('/premium/notifications');
              console.log('Icon clicked');
            },
          },
          {
            side: 'end',
            icon: 'close-circle-outline',
            role: 'cancel',
            handler: () => {
              console.log('Cancel clicked');
            },
          },
        ],
      })
      .then((obj) => obj.present());
  }

  showOnceToast(message) {
    this.toastController
      .dismiss()
      .then((obj) => {})
      .catch(() => {})
      .finally(() => {
        this.showFcmToast(message);
      });
  }
}
