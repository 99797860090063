import { Injectable, ApplicationRef } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { concat, interval, BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { AlertController, Platform, ToastController } from '@ionic/angular';

import { environment } from '@env/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class UpdateService {
  alrt: any;
  toast: any;
  update_isEnabled = false;
  update_isAvailable = false;

  private updateAvailable = new BehaviorSubject<boolean>(false);
  checkAvailableUpdate = this.updateAvailable.asObservable(); // message observable to show in Angular component

  constructor(
    private swUpdate: SwUpdate,
    appRef: ApplicationRef,
    private alertController: AlertController,
    private toastController: ToastController,
    private platform: Platform,
    private router: Router
  ) {
    this.update_isEnabled = this.swUpdate.isEnabled;
    if (!this.swUpdate.isEnabled) {
      console.warn('SW Update not enabled!');
    } else {
      console.warn('SW Update is enabled!');

      this.swUpdate.available.subscribe((event) => {
        // an update is available, add some logic here.
        console.warn('SW Update available!', performance.now());
        this.update_isAvailable = true;
        const versionKey = 'version';
        console.log('current version is', event.current, event.current.appData[versionKey]);
        console.log('available version is', event.available, event.available.appData[versionKey]);
        const newVersion = event.available.appData[versionKey];
        console.warn('prompt user!?');
        if (environment.firebase.projectId == 'concili-dev') {
          this.promptUserForUpdate(newVersion);
        } else {
          this.toastUserForUpdate(newVersion);
        }
        this.updateAvailable.next(true);
      });

      this.swUpdate.activated.subscribe((event) => {
        console.warn('SW Update activated!');
        console.log('old version was', event.previous);
        console.log('new version is', event.current);
      });

      // Allow the app to stabilize first, before starting polling for updates with `interval()`.
      const appIsStable$ = appRef.isStable.pipe(first((isStable) => isStable === true));

      const intervalMs = environment.firebase.projectId == 'concili-dev' ? 1 * 10 * 60 * 1000 : 12 * 60 * 60 * 1000;
      const everySixHours$ = interval(intervalMs); // h * min * sec * ms
      const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

      everySixHoursOnceAppIsStable$.subscribe(() => {
        console.warn(
          environment.firebase.projectId == 'concili-dev' ? 'DEV: every minute' : 'every 12 hours',
          'swUpdate.checkForUpdate()'
        );

        swUpdate.checkForUpdate();
      });
    }
  }

  public checkForUpdates(): void {
    console.log('checkForUpdates()', this.swUpdate.isEnabled);
    if (this.swUpdate.isEnabled) this.swUpdate.checkForUpdate();
  }

  public startUpdate(): void {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.activateUpdate().then(() => {
        console.warn('update finished. will reload now!');
        window.location.reload();
      });
    }
  }

  async promptUserForUpdate(version: string) {
    if (this.alrt !== false) {
      console.warn('promptUserForUpdate() - alert already present', this.alrt);
    }
    this.alrt = await this.alertController.create({
      header: 'App Update!',
      message: 'Eine neue Version ist verfügbar: <strong>' + version + '</strong>',
      cssClass: 'ion-text-center',
      buttons: [
        {
          text: 'Jetzt aktualisieren!',
          cssClass: 'primary',
          handler: () => {
            this.startUpdate();
          },
        },
      ],
    });

    await this.alrt.present();
  }

  async toastUserForUpdate(version: string) {
    if (this.toast !== false) {
      console.warn('toastUserForUpdate() - toast already present', this.alrt);
    }

    // console.log('toastUserForUpdate', version);
    const msg = 'Eine neue Version der Concili App (' + version + ') ist verfügbar';

    this.toast = await this.toastController.create({
      message: msg,
      duration: 0,
      color: 'primary',
      buttons: [
        {
          side: 'end',
          icon: 'reload-outline',
          text: 'Aktualisieren',
          handler: () => {
            this.router.navigateByUrl('/premium/update');
            //this.startUpdate();
          },
        },
        {
          side: 'start',
          icon: 'close-circle-outline',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          },
        },
      ],
    });

    await this.toast.present();
  }
}
