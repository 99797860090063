import { Component, OnInit } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/functions';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { User } from '@interfaces/user';
import { ModalController, NavParams } from '@ionic/angular';
import { AuthService } from '@services/auth/auth.service';
import { FcmService } from '@services/fcm.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-modal-suche-stadt-auswahl-listen',
  templateUrl: './modal-suche-stadt-auswahl-listen.page.html',
  styleUrls: ['./modal-suche-stadt-auswahl-listen.page.scss'],
})
export class ModalSucheStadtAuswahlListenPage implements OnInit {
  public user: User;
  private subscriptionUser: Subscription;

  bookedBodies: { id: string; city: string; bundesland_kurz: string }[] = [];
  listBodies: { id: string; city: string; bundesland: string; bundesland_kurz: string; isSelected: boolean }[] = [];
  selectedBodies: string;

  selectBundesland = '';
  userChangedSelectedBodies = false;
  countSelectedCities = 0;

  listBundeslaender = ['BW', 'BY', 'BE', 'BB', 'HB', 'HH', 'HE', 'MV', 'NI', 'NW', 'RP', 'SL', 'SN', 'ST', 'SH', 'TH'];
  selectedBundeslaender = [];

  constructor(
    private modalController: ModalController,
    private navParams: NavParams,
    public router: Router,
    public auth: AuthService,
    public fcm: FcmService,
    public formBuilder: FormBuilder,
    private fun: AngularFireFunctions
  ) {
    this.subscriptionUser = this.auth.user$.subscribe((user) => {
      if (user) {
        this.user = user;
        this.getAllBodies();
      } else this.user = null;
    });
  }

  ngOnInit() {
    this.selectedBodies = this.navParams.get('selectedBodies');
    this.userChangedSelectedBodies = this.navParams.get('userChangedSelectedBodies');
  }

  async dismissModal() {
    this.modalController.dismiss();
  }

  getAllBodies() {
    // get all Bodies
    this.fun
      .httpsCallable('app-getAllBodies')({})
      .toPromise()
      .then((response) => {
        console.log('modal-getAllBodies response', response);
        this.listBodies = [];
        response.forEach((cty) => {
          if (this.user?.bookedBodiesIds && this.user.bookedBodiesIds.includes(cty.bodyId))
            this.listBodies.push({
              id: cty.bodyId,
              city: cty.city,
              bundesland_kurz: cty.bundesland_kurz,
              bundesland: cty.bundesland,
              isSelected: this.userChangedSelectedBodies && this.selectedBodies.includes(cty.bodyId),
            });
        });
        this.listBodies.sort(this.sortCityNames);
        //this.filterBookedBodies();
        this.updateCountSelectedCities();
        console.log('this.listBodies', this.listBodies);
      });
  }

  sortCityNames(a, b) {
    const nameA = a.city.toUpperCase(); // ignore upper and lowercase
    const nameB = b.city.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0; // if equal
  }

  filterSelectedBodies() {
    if (this.user?.bookedBodiesIds && this.listBodies.length > 0) {
      this.bookedBodies = this.listBodies.filter((cty) => this.user.bookedBodiesIds.includes(cty.id));
    }

    console.warn('filterBookedBodies', this.user?.bookedBodiesIds, this.listBodies, this.bookedBodies);
  }

  async saveAndClose() {
    //const selectedBodies = this.selectedBodies;
    const selectedBodies = this.listBodies.filter((cty) => cty.isSelected == true).map((cty) => cty.id);
    await this.modalController.dismiss(selectedBodies);
  }

  selectAll() {
    this.listBodies.map((cty) => (cty.isSelected = true));
    this.updateCountSelectedCities();
  }

  selectNone() {
    this.listBodies.map((cty) => (cty.isSelected = false));
    this.updateCountSelectedCities();
  }

  selectCity(id: string) {
    this.listBodies.map((cty) => {
      if (cty.id == id) cty.isSelected = true;
      return cty;
    });
    this.updateCountSelectedCities();
  }

  unselectCity(id: string) {
    this.listBodies.map((cty) => {
      if (cty.id == id) cty.isSelected = false;
      return cty;
    });
    this.updateCountSelectedCities();
  }

  updateCountSelectedCities() {
    this.countSelectedCities = this.listBodies.filter((cty) => cty.isSelected == true).length;
  }

  selectBL(bl) {
    if (this.selectedBundeslaender.includes(bl))
      this.selectedBundeslaender = this.removeItemFromArray(this.selectedBundeslaender, bl);
    else this.selectedBundeslaender.push(bl);
  }

  sortCityBundesland(a, b) {
    const nameA = a.bundesland_kurz.toUpperCase(); // ignore upper and lowercase
    const nameB = b.bundesland_kurz.toUpperCase(); // ignore upper and lowercase
    if (this.selectBundesland && nameA == this.selectBundesland) return 1;
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0; // if equal
  }

  removeItemFromArray<T>(arr: Array<T>, value: T): Array<T> {
    const index = arr.indexOf(value);
    if (index > -1) arr.splice(index, 1);
    return arr;
  }
}
