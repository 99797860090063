import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ModalSucheStadtAuswahlListenPageRoutingModule } from './modal-suche-stadt-auswahl-listen-routing.module';

import { ModalSucheStadtAuswahlListenPage } from './modal-suche-stadt-auswahl-listen.page';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MaterialModule } from 'src/app/material.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ModalSucheStadtAuswahlListenPageRoutingModule,
    MaterialModule,
    FontAwesomeModule,
  ],
  declarations: [ModalSucheStadtAuswahlListenPage],
})
export class ModalSucheStadtAuswahlListenPageModule {}
