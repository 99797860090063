// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  serviceWorker: true,
  recaptchaSiteKey: '6Lf9dOEUAAAAAEko8Zdii5bYVWwzSA5pifG-fHBl',
  ghostUrl: 'https://ghost.concili.de',
  ghostApiKey: 'b9d900f5493bd7738af69a1e6b',
  firebase: {
    apiKey: 'AIzaSyD-6A_LsOAa07UGgjZcmwefk-KNFOlEA_A',
    authDomain: 'concili-dev.firebaseapp.com',
    databaseURL: 'https://concili-dev.firebaseio.com',
    projectId: 'concili-dev',
    storageBucket: 'concili-dev.appspot.com',
    messagingSenderId: '940220994124',
    appId: '1:940220994124:web:5cf9ce989ad40ed12c1ae9',
  },
  searchEngine: 'appsearch',
  searchCall: 'functions',
  emulator: false,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
