import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@services/auth/auth.guard';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () => import('./public/login/login.module').then((m) => m.LoginPageModule),
  },
  {
    path: 'login-error',
    loadChildren: () => import('./public/login-error/login-error.module').then((m) => m.LoginErrorPageModule),
  },
  {
    path: 'invite',
    loadChildren: () => import('./public/invite/invite.module').then((m) => m.InvitePageModule),
  },
  {
    path: 'einladung',
    loadChildren: () => import('./public/invite/invite.module').then((m) => m.InvitePageModule),
  },
  { path: 'register', redirectTo: 'login', pathMatch: 'prefix' },
  /*{
    path: 'register',
    loadChildren: () => import('./public/register/register.module').then((m) => m.RegisterPageModule),
  },
  {
    path: 'register-success',
    loadChildren: () =>
      import('./public/register-success/register-success.module').then((m) => m.RegisterSuccessPageModule),
  },*/
  {
    path: 'reset-password',
    loadChildren: () => import('./public/reset-password/reset-password.module').then((m) => m.ResetPasswordPageModule),
  },
  {
    path: 'impressum',
    loadChildren: () => import('./public/impressum/impressum.module').then((m) => m.ImpressumPageModule),
  },
  {
    path: 'datenschutz',
    loadChildren: () => import('./public/datenschutz/datenschutz.module').then((m) => m.DatenschutzPageModule),
  },

  {
    path: 'version',
    loadChildren: () => import('./public/version/version.module').then((m) => m.VersionPageModule),
  },

  {
    path: 'premium',
    canActivate: [AuthGuard],
    loadChildren: () => import('./premium/premium-routing.module').then((m) => m.PremiumRoutingModule),
  },

  { path: '**', redirectTo: 'login', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
