import { Component, HostListener } from '@angular/core';
import { Router, RouterEvent } from '@angular/router';

import { Platform } from '@ionic/angular';
import { Plugins, StatusBarStyle } from '@capacitor/core';

import { filter } from 'rxjs/operators';

//import { environment } from '@env/environment';
import { AuthService } from '@services/auth/auth.service';
import { UpdateService } from '@services/update.service';
import { ScreensizeService } from '@services/screensize.service';
import { SearchJob, SearchJobsService } from '@services/search/search-jobs.service';

// icons
import { faSearch, faFolderDownload } from '@fortawesome/pro-light-svg-icons';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
})
export class AppComponent {
  private appPagesPremium = [
    { title: 'Suche', url: '/premium/suche', icon: faSearch },
    { title: 'Gespeicherte<br>Suchaufträge', url: '/premium/gespeicherte-suchen', icon: faFolderDownload },
    //{ title: 'Logout', url: '/premium/logout', icon: faSignOut },
  ];

  public appPagesMenu = this.appPagesPremium;
  public allSearchJobs: SearchJob[];
  public appSearchJobs: SearchJob[];

  public isUserSignedIn = false;
  public activePath = '/login';
  public isTablet = false;
  public updateAvailable = false;
  public isUserPremium = false;

  deferredPrompt: any;
  public showPWAButton = false;

  constructor(
    private platform: Platform,
    private router: Router,
    private screensizeService: ScreensizeService,
    private swUpdate: UpdateService,
    public auth: AuthService,
    private searchJobsService: SearchJobsService
  ) {
    this.initializeApp();

    this.router.events.pipe(filter((e): e is RouterEvent => e instanceof RouterEvent)).subscribe((e: RouterEvent) => {
      this.activePath = e.url;
    });

    this.auth.user$.subscribe((user) => {
      console.warn('app auth user', user);
      this.isUserSignedIn = !!(user && user.roles?.user);

      if (user) this.isUserPremium = user.roles?.premium;
    });
    this.searchJobsService.userSearchJobs$.subscribe((jobs) => {
      if (jobs) {
        console.warn('app userSearchJobs subscribe', jobs);
        this.allSearchJobs = jobs;
        this.appSearchJobs = jobs?.filter((j) => j.showMenu).slice(0, 5);
      }
    });

    this.screensizeService.isTabletView().subscribe((isTablet) => {
      this.isTablet = isTablet;
    });

    this.swUpdate.checkAvailableUpdate.subscribe((chkUpd) => {
      console.warn('app: SW Update available!?', chkUpd, performance.now());
      this.updateAvailable = chkUpd;
    });
  }

  async initializeApp() {
    const { SplashScreen, StatusBar } = Plugins;
    try {
      await SplashScreen.hide();
      await StatusBar.setStyle({ style: StatusBarStyle.Light });
    } catch (err) {
      console.log('This is normal in a browser', err);
    }
  }

  showSideMenu() {
    //console.warn('showSideMenu', this.showSideMenu, this.isTablet, this.activePath);

    // show fixed pane when desktop and premium
    if (!this.activePath.startsWith('/premium')) return false;
    if (this.isUserSignedIn) {
      if (!this.isTablet) return false;
      return true;
    }
    return false;
  }

  @HostListener('window:beforeinstallprompt', ['$event'])
  onbeforeinstallprompt(e) {
    console.log('app.component - onbeforeinstallprompt', e);
    // Prevent Chrome 67 and earlier from automatically showing the prompt
    e.preventDefault();
    // Stash the event so it can be triggered later.
    this.deferredPrompt = e;
    this.showPWAButton = true;
  }

  addToHomeScreen() {
    console.log('App.component - addToHomeScreen');
    if (!this.deferredPrompt) {
      console.warn('no deferredPrompt -> return!');
      return;
    }

    // hide our user interface that shows our A2HS button
    this.showPWAButton = false;
    // Show the prompt
    this.deferredPrompt.prompt();
    // Wait for the user to respond to the prompt
    this.deferredPrompt.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        console.log('User accepted the A2HS prompt');
      } else {
        console.log('User dismissed the A2HS prompt');
      }
      this.deferredPrompt = null;
    });
  }

  showAllJobs() {
    this.appSearchJobs = this.allSearchJobs;
  }
}
