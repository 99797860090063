<ion-split-pane [when]="showSideMenu()"  contentId="conMain" class="con-app__splitpane">
  <!--  the side menu  -->
  <ion-menu menuId="conMenu" contentId="conMain" >
    

    <ion-content class="con-app__menu">
      <div class="con-app__menu-logo" [routerDirection]="'root'" routerLink="/premium/suche">
        <img alt="Concili" src="/assets/img/concili-logo-180px@2x.png" class="con-app__logo-img">
      </div>


      <ion-list class="con-app__menu-list" [class.with-search-jobs]="appSearchJobs" lines="none">
        <ion-menu-toggle auto-hide="false" *ngFor="let p of appPagesMenu">

          <ion-item class="con-app__menu-item" button [routerDirection]="'root'" [routerLink]="[p.url]" routerLinkActive="active-link">
            <span class="con-app__menu-icon"><fa-icon [icon]="p.icon"></fa-icon></span>
            <ion-label [innerHTML]="p.title">
              
            </ion-label>
          </ion-item>

        </ion-menu-toggle>
      </ion-list>

      <div class="con-app__menu-search-jobs" *ngIf="isUserPremium && appSearchJobs && appSearchJobs.length>0">
        
        <ion-list class="con-app__menu-list" lines="none">
          <ion-menu-toggle auto-hide="false" *ngFor="let job of appSearchJobs">
  
            <ion-item class="con-app__menu-searchjobs-item"  button [routerDirection]="'root'" [routerLink]="'/premium/suche/gespeichert/' + job.id +'/'+job.searchTerm" routerLinkActive="active-link">
              <span class="con-app__menu-searchjobs-icon">
                <fa-icon *ngIf="!job.emailAlert" [icon]="['fal','file-search']"></fa-icon>
                <fa-icon *ngIf="job.emailAlert" [icon]="['fal','envelope']"></fa-icon>
              </span>

              <ion-label>
                
                {{ job.description || job.searchTerm }} 
                
                <br>
                <span class="con-app__menu-searchjobs-bodies"> 
                  <fa-icon [icon]="['fal','map-marker']" size="sm"></fa-icon>
                  {{ job.bodiesList}}
                </span>
              </ion-label>
            </ion-item>
  
          </ion-menu-toggle>
          

        </ion-list>
      </div>
      

      
      <div class="con-app__menu-bottom" slot="fixed">
        <p class="ion-text-center" *ngIf="showPWAButton">
          <ion-button (click)="addToHomeScreen()" color="light">
            <fa-icon [icon]="['fal','cloud-download']" size="lg" class="fa-btn-icon"></fa-icon>
            APP installieren
          </ion-button>
        </p>
        <p class="ion-text-center" *ngIf="!showPWAButton && updateAvailable">
          <a routerLink="/premium/update">
            <ion-text color="primary"><strong>Update verfügbar!</strong></ion-text>
          </a>
        </p>
        <ion-menu-toggle auto-hide="false" >
          <p class="ion-text-center">
            <a href="https://concili.de/impressum/" target="_blank">Impressum</a> | 
            <a href="https://concili.de/datenschutz/" target="_blank">Datenschutz</a>      
          </p>
        </ion-menu-toggle>
      </div>


    </ion-content>

    
  </ion-menu>

  <!-- the main content -->
  <ion-router-outlet id="conMain"></ion-router-outlet>
</ion-split-pane>