import { ModalSucheStadtAuswahlListenPageModule } from '@modals/modal-suche-stadt-auswahl-listen/modal-suche-stadt-auswahl-listen.module';

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@env/environment';

import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule, SETTINGS } from '@angular/fire/firestore';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireFunctionsModule, REGION, ORIGIN } from '@angular/fire/functions';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
  //far,
  faUserCircle,
} from '@fortawesome/pro-regular-svg-icons';
import {
  //fal,
  faCloudDownload,
  faEdit,
  faEnvelope,
  faFileSearch,
  faMapMarker,
  faMapMarkerExclamation,
  faMapMarkerPlus,
  faMapMarkerEdit,
  faMapMarkerAlt,
  faSync,
  faTrashAlt,
  faLayerMinus,
  faLayerPlus,
  faSearch,
  faTimesSquare,
  faUserCog,
} from '@fortawesome/pro-light-svg-icons';
import { faCheckSquare } from '@fortawesome/pro-solid-svg-icons';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireMessagingModule,
    AngularFireFunctionsModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ModalSucheStadtAuswahlListenPageModule,
    ServiceWorkerModule.register('combined-sw.js', { enabled: environment.serviceWorker }),
    FontAwesomeModule,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: REGION, useValue: 'europe-west3' },
    {
      provide: SETTINGS,
      useValue: environment.emulator
        ? {
            host: 'localhost:8084',
            ssl: false,
          }
        : undefined,
    },
    { provide: ORIGIN, useValue: environment.emulator ? 'http://localhost:5001' : undefined },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(library: FaIconLibrary) {
    //if (environment.production) {
    library.addIcons(
      faUserCircle,
      faCloudDownload,
      faEdit,
      faEnvelope,
      faFileSearch,
      faMapMarker,
      faMapMarkerExclamation,
      faMapMarkerPlus,
      faMapMarkerEdit,
      faMapMarkerAlt,
      faSync,
      faTrashAlt,
      faLayerMinus,
      faLayerPlus,
      faSearch,
      faTimesSquare,
      faCheckSquare,
      faUserCog
    );
    /*} else {
      library.addIconPacks(far, fal);
    }*/
  }
}
